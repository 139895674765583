'use strict';
import { BusinessTenant } from '@Client/runner.services/auth.service';
import angular, { dialog } from 'angular';

class TenantDialogController {
  static $inject = ['$scope', 'authService', 'ngDialog'];

  public modalTitle = 'Switch Tenant';
  public waitingForApi = true;
  public activeTenantId: string;
  public selectedTenant: BusinessTenant;
  public userBusinessId: string;
  public tenants: BusinessTenant[];
  private tenantsLoaded: angular.IPromise<BusinessTenant[]>;
  public activeTenantName: string;
  public userBusinessName: string;

  constructor(
    private $scope: IScopeNgDialog,
    private authService: AuthService,
    private ngDialog: dialog.IDialogService
  ) {
    this.activeTenantId = this.$scope.ngDialogData.tenant.id.toLowerCase();
    this.userBusinessId = this.$scope.ngDialogData.userBusinessId.toLowerCase();
    this.loadTenants();
  }

  loadTenants() {
    this.tenantsLoaded = this.authService.getTenants().then((tenants) => {
      this.tenants = tenants.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      this.selectedTenant = this.tenants.find(
        (tenant) => tenant.id === this.activeTenantId
      );
      this.activeTenantName = this.selectedTenant?.name;
      const userBusiness = this.tenants.find(
        (tenant) => tenant.id === this.userBusinessId
      );
      this.userBusinessName = userBusiness?.name;
      this.waitingForApi = false;
      return this.tenants;
    });
  }

  changeTenant(newTenantId) {
    this.waitingForApi = true;

    this.authService.changeTenant(newTenantId).then(
      (success) => {
        const selectedTenant = this.tenants.find(
          (tenant) => tenant.id === newTenantId
        );
        this.closeDialog(selectedTenant.name);
        location.reload();
      },
      (error) => {
        this.waitingForApi = false;
      }
    );
  }

  closeDialog(result) {
    this.ngDialog.closeAll(result);
  }

  getFilteredBusinesses = (search) => {
    const searchTerm = search.term?.toLowerCase();
    if (!searchTerm) {
      return this.tenantsLoaded;
    }
    return this.tenants.filter(
      (tenant) => tenant.name?.toLowerCase().indexOf(searchTerm) > -1
    );
  };
}

angular
  .module('flowingly.runner')
  .controller('tenantDialogController', TenantDialogController);
