import angular, {
  IAuth0LockProvider,
  IAuth0Provider,
  ICompileProvider,
  IHttpProvider,
  IJWTOptionsProvider,
  ILocationProvider,
  cookies,
  idle
} from 'angular';
import { IFlowinglyWindow } from './interfaces/flowingly.window';
import { FormGen } from './@types/formGen';
import { SharedAngular } from './@types/sharedAngular';

/*
 * Converted runner.config.js to ts on 17/12/2019
 * To view the original version open this
 * https://bitbucket.org/flowingly-team/flowingly-source-code/src/3a5769f998d23e3923099dcb44996d7cf1dd02b1/src/Flowingly.Angular.Runner/Client/runner.config.js
 */

declare const window: IFlowinglyWindow;

// Create the module and declare its dependencies.
angular.module('flowingly.runner').config([
  '$httpProvider',
  '$locationProvider',
  '$compileProvider',
  'usSpinnerConfigProvider',
  'fgConfigProvider',
  'lockProvider',
  'angularAuth0Provider',
  'APP_CONFIG',
  'jwtOptionsProvider',
  'storeProvider',
  'IdleProvider',
  '$cookiesProvider',
  'angularAuth0SpaSdkProvider',
  function (
    $httpProvider: IHttpProvider,
    $locationProvider: ILocationProvider,
    $compileProvider: ICompileProvider,
    usSpinnerConfigProvider: IUsSpinnerConfig,
    fgConfigProvider: FormGen.FgConfigProvider,
    lockProvider: IAuth0LockProvider,
    angularAuth0Provider: IAuth0Provider,
    APP_CONFIG: SharedAngular.APP_CONFIG,
    jwtOptionsProvider: IJWTOptionsProvider,
    storeProvider: angular.a0.storage.IStoreProvider,
    IdleProvider: idle.IIdleProvider,
    $cookiesProvider: cookies.ICookiesProvider,
    angularAuth0SpaSdkProvider: SharedAngular.AngularAuth0SpaSdk
  ) {
    // populate settings.json from window to APP_CONFIG
    APP_CONFIG.populateFromSettingsJSON(window.settings);

    // Store defaults to localStorage but we can set sessionStorage or cookieStorage.
    storeProvider.setStore('localStorage');
    if (APP_CONFIG.flowinglyEnvironment === 'production') {
      $httpProvider.useApplyAsync(true);
      $compileProvider.debugInfoEnabled(true);
    }
    fgConfigProvider.setShowDummyDoneButton(false);

    // Set the color used for the in progress spinner
    usSpinnerConfigProvider.setDefaults({ color: '#f57c00' });

    // Implement the method to get the token, which is used by authManager.checkAuthOnRefresh()
    jwtOptionsProvider.config({
      tokenGetter: function () {
        return localStorage.getItem(
          'flowingly.' + APP_CONFIG.flowinglyEnvironment + '.token'
        );
      },
      whiteListedDomains: ['localhost'], // Not sure exactly what this does. Want to see it in Dev so I can work it out.
      unauthenticatedRedirectPath: '/' // Our main route handles it when user is not authenticated
    });

    $httpProvider.interceptors.push('runnerAuthInterceptor');
    $httpProvider.interceptors.push('runnerSpinInterceptor');
    $httpProvider.interceptors.push('flowinglyHttpErrorInterceptor');

    $locationProvider.html5Mode(true).hashPrefix('');

    $httpProvider.defaults.useXDomain = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];

    const domainPort =
      window.location.port !== '' ? `:${window.location.port}` : '';
    const currentDomain = `${window.location.protocol}//${window.location.hostname}${domainPort}`;
    const pushNotifHandle =
      window.isFlowinglyCordova &&
      window.flowinglyCordova.pushNotificationHandle != null &&
      window.flowinglyCordova.pushNotificationHandle
        ? '?pushNotificationHandle=' +
          window.flowinglyCordova.pushNotificationHandle
        : '';

    // Determine if the app is inside an iframe
    const isInIframe = window !== window.parent;

    // Determine the redirectUri based on the iframe and URL conditions
    let redirectUri = `${currentDomain}/flowsactive/${pushNotifHandle}`;
    if (isInIframe) {
      redirectUri = `${currentDomain}/iframe/`;
    }

    lockProvider.init({
      clientID: APP_CONFIG.auth0ClientId,
      domain: APP_CONFIG.auth0Domain,
      options: {
        rememberLastLogin: false
      }
    });

    angularAuth0Provider.init({
      clientID: APP_CONFIG.auth0ClientId,
      domain: APP_CONFIG.auth0Domain,
      responseType: 'id_token token',
      scope:
        'openid email offline_access nameidentifier businessidentifier flowinglypermissions', //offline_access so that we get a refresh token
      redirectUri: redirectUri
    });

    angularAuth0SpaSdkProvider.init({
      clientId: APP_CONFIG.auth0AppV2ClientId,
      domain: APP_CONFIG.auth0Domain,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: redirectUri,
        scope:
          'openid email offline_access nameidentifier businessidentifier flowinglypermissions'
      }
    });

    IdleProvider.idle(APP_CONFIG.sessionTimeoutInSecond);
    IdleProvider.timeout(0);

    $cookiesProvider.defaults.path = '/';
    $cookiesProvider.defaults.domain = window.location.hostname.substr(
      window.location.hostname.indexOf('.') + 1
    );
  }
]);

angular.module('flowingly.runner').factory('$exceptionHandler', [
  'exceptionService',
  function (exceptionService) {
    return function (exception, cause) {
      exceptionService.error(exception, cause);
    };
  }
]);
