import { SharedAngular } from '@Client/@types/sharedAngular';
import { FlowinglyPermissions } from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular, { IQService } from 'angular';
import { IStateService } from 'angular-ui-router';

class RunnerLibraryTabsComponentController {
  businessName: string;
  canCreateFlows: boolean;
  helpUri: string;
  flowModels: any[] = [];
  showBulkImportWhenEmpty: boolean;
  loading = true;

  static $inject = [
    '$state',
    'sessionService',
    'brandingService',
    'permissionsService',
    'flowModelApiService',
    'dialogService',
    '$q'
  ];

  constructor(
    private $state: IStateService,
    private sessionService: SharedAngular.SessionService,
    private brandingService: SharedAngular.BrandingService,
    private permissionsService: SharedAngular.PermissionsService,
    private flowModelApiService: SharedAngular.FlowModelApiService,
    private dialogService: SharedAngular.DialogService,
    private $q: IQService
  ) {
    this.businessName = this.sessionService.getBusinessName();
    this.helpUri = this.brandingService.getBrandedHelpUri(
      'https://help.flowingly.net/coming-soon/how-do-i-create-manage-processes-for-my-business'
    );
    this.canCreateFlows = this.permissionsService.currentUserHasPermission(
      FlowinglyPermissions.FLOWMODEL_CREATE
    );
    this.showBulkImportWhenEmpty =
      this.permissionsService.currentUserHasPermission(
        FlowinglyPermissions.FLOWMODEL_BULKIMPORT
      );
    this.fetchFlowModels();
  }

  fetchFlowModels = () => {
    const deferred = this.$q.defer();
    this.flowModelApiService
      .getFlowModels()
      .then((result) => {
        this.flowModels = result;
        this.loading = false;
        const currentTab = window.location.pathname
          .split('/')
          .filter(Boolean)
          .pop();
        this.swichTab(currentTab);
        deferred.resolve(result);
      })
      .catch((error) => {
        console.error('Error fetching flow models and categories:', error);
        deferred.reject(error);
      });
    return deferred.promise;
  };

  openBulkImporter = () => {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.import/flowmodels/runner.import.flowmodels.dialog.tmpl.html',
        controller: 'importFlowModelsDialogController',
        appendClassName: 'ngdialog-normal'
      })
      .then(this.onListUpdated);
  };

  swichTab = (tabName) => {
    switch (tabName.toLowerCase()) {
      case 'workflows':
        this.$state.go('app.runner.library.workflows', {
          flowModels: this.flowModels
        });
        break;
      case 'processmaps':
        this.$state.go('app.runner.library.processmaps', {
          flowModels: this.flowModels
        });
        break;
      case 'drafts':
        this.$state.go('app.runner.library.drafts', {
          flowModels: this.flowModels
        });
        break;
      default:
        this.$state.go('app.runner.library.workflows', {
          flowModels: this.flowModels
        });
    }
  };

  onListUpdated(needResizeGrid) {
    this.fetchFlowModels().then(function () {
      if (needResizeGrid) {
        this.gridNeedResize = true;
      }
    });
  }
}

export class RunnerLibraryTabsComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.library/runner.library.tabs.component.tmpl.html';

  public bindings = {
    flowModels: '<'
  };

  public controller = [
    '$state',
    'sessionService',
    'brandingService',
    'permissionsService',
    'flowModelApiService',
    'dialogService',
    '$q',
    RunnerLibraryTabsComponentController
  ];
}

angular
  .module('flowingly.runner.library')
  .component('runnerLibraryTabs', new RunnerLibraryTabsComponent());
