import angular from 'angular';
import { SharedAngular } from '@Client/@types/sharedAngular';
import { IApiPublicFormModel } from '@Client/runner.services/runner.public-form.api.service';
import StepService from '@Client/runner.services/step.service';

class RunnerPublicFormController {
  static $inject = [
    '$scope',
    '$stateParams',
    'momentService',
    'runnerPublicFormService',
    'notificationService',
    'guidService',
    'flowinglyConstants',
    '$state',
    'redirectService',
    'runnerCardService',
    'stepService',
    'appInsightsService',
    'pubsubService'
  ];

  private defaultBgColor = '#eeeeee';
  private backgroundColour = this.defaultBgColor;
  private formData: IApiPublicFormModel;
  private data;
  private showForm = true;
  private submitSuccess = false;
  private errorGetForm = false;
  private stepId = null;
  private isLoading = true;
  private submitRequestPending = false;

  constructor(
    private $scope,
    private $stateParams,
    private momentService,
    private runnerPublicFormService: RunnerPublicFormService,
    private notificationService,
    private guidService,
    private flowinglyConstants,
    private $state,
    private redirectService,
    private runnerCardService,
    private stepService: StepService,
    private appInsightsService: SharedAngular.AppInsightsService,
    private pubsubService: SharedAngular.PubSubService
  ) {}

  $onInit() {
    const errorHandler = (err) => {
      this.resetFlag();
      if (err) {
        if (err.status === 401) {
          // user doesn't have permission to view the public form
          this.redirectService.setPendingRequestAsUrl(
            `/form/${this.$stateParams.id}`
          );
          this.$state.go('app.login');
        }
      } else {
        this.errorGetForm = true;
        this.isLoading = false;
      }
    };
    this.stepId = this.guidService.new();
    this.runnerPublicFormService.getFormById(this.$stateParams.id).then(
      (data) => {
        if (data === undefined) {
          errorHandler(null);
        } else {
          angular.element('.loading').removeClass('loading');
          this.isLoading = false;
          this.formData = data;
          // TODO once switchover to new infrastructure is complete we can update
          // the brand logo setting instead of modifying it
          const legacyPath = '/Client/dist/';
          if (
            this.formData.brandLogo &&
            this.formData.brandLogo.indexOf(legacyPath) > -1
          ) {
            this.formData.brandLogo =
              this.formData.brandLogo.split(legacyPath)[1];
          }
          this.backgroundColour =
            this.formData.backgroundColor &&
            this.formData.backgroundColor !== ''
              ? this.formData.backgroundColor
              : this.defaultBgColor;
          angular
            .element('.public-form')
            .css('background-color', this.backgroundColour);
          this.initialiseFormData(this.formData);
        }
      },
      (err: any) => {
        errorHandler(err);
      }
    );
  }

  submitForm(data, schema) {
    this.appInsightsService.startEventTimer('publicFormSubmitted');
    this.submitRequestPending = true;
    const fileUploadData = this.runnerCardService.createFileUploadData(
      this.stepId,
      schema
    );
    data = this.runnerCardService.formatFieldsWithCustomDBUserTypeToObject(
      data,
      schema
    );
    const validatedData = this.stepService.validateForm(
      data,
      schema,
      this.$scope.publicForm,
      fileUploadData,
      true,
      this.stepId
    );
    const isValid = this.stepService.validateFormData(validatedData);

    if (!isValid || !validatedData.form.$valid) {
      this.notificationService.showErrorToast(
        'Oops, a validation error has occurred, please review the fields on the current step for more details.'
      );
      this.submitRequestPending = false;
      this.pubsubService.publish('CLEAR_CAPTCHA');
      return;
    }

    const {
      publicFormId,
      stepName,
      businessId: publicFormBusinessId,
      userId: publicFormUserId
    } = this.formData;
    this.runnerPublicFormService
      .submitForm(
        this.$stateParams.id,
        publicFormId,
        validatedData.formData,
        this.stepId
      )
      .then((response) => {
        this.submitRequestPending = false;
        if (response.data) {
          this.resetFlag();
          this.submitSuccess = true;
        } else {
          this.notificationService.showErrorToast('Form submit failed');
        }
        this.appInsightsService.trackMetricIfTimerExist('publicFormSubmitted', {
          publicFormId,
          stepName,
          publicFormBusinessId,
          publicFormUserId
        });
      })
      .catch(() => {
        this.submitRequestPending = false;
        this.pubsubService.publish('CLEAR_CAPTCHA');
      });
  }

  resetFlag() {
    this.showForm = false;
    this.submitSuccess = false;
    this.errorGetForm = false;
  }

  initialiseFormData(step: IApiPublicFormModel) {
    const schemaFields = step.schema.fields;
    const fields = step.fields;
    for (const f of schemaFields) {
      const matchField = fields.find((field) => field.name === f.name);
      let parsedValue = undefined;

      if (matchField) {
        switch (f.type.toLowerCase()) {
          case this.flowinglyConstants.formFieldType.DATE:
            if (f.defaultValueOption === 'autoPopulate')
              parsedValue = this.momentService
                .utc(new Date())
                .local()
                .format('DD/MM/YYYY');
            break;
          case this.flowinglyConstants.formFieldType.DATETIME:
            if (f.defaultValueOption === 'autoPopulate')
              parsedValue = this.momentService
                .utc(new Date())
                .local()
                .format('DD/MM/YYYY h:mm:ss A'); // as in datetime kendo ui format
            break;
          default:
            parsedValue = matchField.value;
            break;
        }

        fields[f.name] = parsedValue;
      }
    }
  }
}

angular
  .module('flowingly.runner.public-form')
  .controller('runnerPublicFormController', RunnerPublicFormController);
