/**
 * @ngdoc directive
 * @name rolesListActions
 * @module flowingly.runner.setup
 * @description This component is used to display actions button list in a roles row
 * @usage
 * ```
     <roles-list-actions role='dataItem' on-roles-updated='$ctrl.handleRolesUpdated()'></roles-list-actions>
 * ``` 
 * #### Inputs
 * role: role object to act upon
 * onRolesUpdated: event listener when role is updated
 */
import {
  DeleteEntityTypes,
  FlowinglyPermissions
} from '@Shared.Angular/flowingly.services/flowingly.constants';
import angular from 'angular';

export default class RolesListActionsComponentController {
  static $inject = [
    '$scope',
    'dialogService',
    'notificationService',
    'permissionsService',
    'roleApiService'
  ];

  //bindings
  private role;
  private onRolesUpdated;

  private canDeleteRole = false;
  private canEditRole = false;

  constructor(
    private $scope,
    private dialogService,
    private notificationService,
    private permissionsService,
    private roleApiService
  ) {}

  $onChanges(changes) {
    if (changes.role) {
      this.canDeleteRole =
        !this.role.isDefault &&
        this.permissionsService.currentUserHasPermission(
          FlowinglyPermissions.SETUP_ROLE_DELETE
        );
      this.canEditRole =
        !this.role.isDefault &&
        this.permissionsService.currentUserHasPermission(
          FlowinglyPermissions.SETUP_ROLE_UPDATE
        );
    }
  }

  editClick() {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.setup/runner.setup.roles/runner.setup.roles.dialog.tmpl.html',
        controller: 'roleDialogController',
        appendClassName: 'ngdialog-normal',
        scope: this.$scope,
        data: { role: this.role }
      })
      .then((successSaved) => {
        if (this.dialogService.isCloseModalWithCancelAction(successSaved)) {
          return;
        }

        if (successSaved === true) {
          this.onRolesUpdated();
        }
      });
  }

  deleteClick() {
    this.dialogService
      .showDialog({
        template:
          'Client/runner.setup/runner.setup.delete/runner.setup.delete.dialog.tmpl.html',
        controller: 'setupDeleteDialogController',
        appendClassName: 'ngdialog-normal',
        scope: this.$scope,
        data: {
          itemToDelete: this.role,
          entityType: DeleteEntityTypes.roleEntityType
        }
      })
      .then((response) => {
        if (this.dialogService.isCloseModalWithCancelAction(response)) {
          return;
        }

        this.roleApiService.deleteRole(this.role.id).then(() => {
          this.notificationService.showSuccessToast(
            `${this.role.name} successfully Deleted.`,
            1000
          );
          this.onRolesUpdated();
        });
      });
  }
}

export class RolesListActionsComponent implements angular.IComponentOptions {
  public templateUrl =
    'Client/runner.setup/runner.setup.roles/runner.setup.roles.list.actions.tmpl.html';
  public bindings = {
    role: '<',
    onRolesUpdated: '&'
  };
  controller = RolesListActionsComponentController;
}

angular
  .module('flowingly.runner.setup')
  .component('rolesListActions', new RolesListActionsComponent());
