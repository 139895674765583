/**
 * @ngdoc service
 * @name runnerFlowsFormatter
 * @module flowingly.runner.services
 *
 * @description Service repsonsible for formatting flows im in
 *
 * ## Notes
 * ###API
 * * format - format the server response into something the client side can consume.

 * 
*/

import angular from 'angular';
import {
  IFormattedFlow as IFlow,
  IFormattedFlow
} from '../interfaces/IFormattedFlow';
import { IUserBasicInfo } from '../interfaces/user.interface';
import { IStep } from '../interfaces/IStep';
import { SharedAngular } from '@Client/@types/sharedAngular';

const fieldTypes = {
  approvecomment: 0,
  approval: 1,
  checkbox: 3,
  currency: 4,
  fileupload: 6,
  instruction: 7,
  table: 8,
  textArea: 10,
  number: 12,
  radiobuttonlist: 14,
  dropdown: 15,
  tasklist: 17,
  multiselectlist: 18,
  signature: 21,
  dynamicactors: 22,
  lookup: 23,
  attachdocument: 25,
  image: 26,
  recaptcha: 27
};

export interface IFlowFormatOptions {
  replaceLoggedInUserWithYou: boolean;
}

const defaultFlowFormatOption: IFlowFormatOptions = {
  replaceLoggedInUserWithYou: true
};

export class FormattingTool {
  private user;
  private config: IFlowFormatOptions;

  public constructor(config: IFlowFormatOptions) {
    this.config = config;
  }

  public setCurrentUser(user) {
    this.user = user;
  }

  /**
   * @deprecated use .getNameToDisplay which is more reliable
   * @param taskActorName
   */
  public getActorName(taskActorName) {
    if (this.config.replaceLoggedInUserWithYou) {
      taskActorName = taskActorName && taskActorName.trim();

      //if the name is the same as the logged in user, return "you"
      if (this.user && this.user.fullName === taskActorName) {
        return 'You';
      } else {
        return taskActorName;
      }
    } else {
      return taskActorName;
    }
  }
}

/**
 * Converted to ts from js on 13/11/2019. To see the original
 * JS version diff this file with flows.formatter.js on commit
 * dfa53bd1107e81cbe686b6a0e662e350df8da6b2
 */
export class RunnerFlowsFormatterService {
  static $inject = [
    'flowinglyConstants',
    'sessionService',
    'momentService',
    'lodashService',
    'flowsUtilityService',
    'validationService',
    'fileService',
    'flowinglyMomentService',
    'currencyService',
    'kendoService',
    'tokenService',
    'APP_CONFIG'
  ];

  constructor(
    private flowinglyConstants: SharedAngular.FlowinglyConstants,
    private sessionService: SharedAngular.SessionService,
    private momentService: Moment,
    private lodashService: Lodash,
    private flowsUtilityService: SharedAngular.FlowsUtilityService,
    private validationService: SharedAngular.ValidationService,
    private fileService: SharedAngular.FileService,
    private flowinglyMomentService: SharedAngular.FlowinglyMomentService,
    private currencyService: SharedAngular.CurrencyService,
    private kendo: Kendo,
    private tokenService: SharedAngular.TokenService,
    private appConfig: SharedAngular.APP_CONFIG
  ) {}

  private fileserviceInitiated = false;

  //////////// Public API Methods
  public removeGroupsWithoutFlows(groupedFlows): void {
    // [FLOW-5699] Remove groups that doesn't have flows in it.
    if (groupedFlows != null) {
      this.lodashService.remove(groupedFlows, (group) => {
        if (group != null) {
          return group.Flows.length === 0;
        }
      });
    }
  }

  /*
   * GetFlows API Data Model returned:
   *
   * DataModel
   *  - FlowModels
   *  - GroupedFlows
   *  - - GroupName
   *  - - Flows
   *  - - - Steps
   *  - - - - Fields
   */
  public format(groupedFlows, sortBy, sortAscending = true) {
    if (!groupedFlows || groupedFlows.length === 0) {
      return;
    }
    this.lodashService.forEach(groupedFlows, (group) => {
      // If given sort by param, sort the flow accordingly
      // Now assume sort by is date time object, if need sort on other data type, we can update code later on
      if (sortBy) {
        group.Flows.sort((f1, f2) => {
          if (this.momentService(f1[sortBy]).isBefore(f2[sortBy])) {
            return sortAscending ? -1 : 1;
          }

          if (this.momentService(f1[sortBy]).isAfter(f2[sortBy])) {
            return sortAscending ? 1 : -1;
          }

          return 0;
        });
      }

      // transform each IFlow into an IFormattedFlow
      for (let i = 0; i < group.Flows.length; i++) {
        group.Flows[i] = this.formatFlow(group.Flows[i]);
      }
    });
    return;
  }

  public getStartedByText(flow: IFlow, shouldIncludeTime = false): string {
    const starter = flow.WhoStarted;
    const requester = flow.RequestedByUser
      ? this.getNameToDisplay(flow.RequestedByUser)
      : null;

    let text: string;
    if (requester && requester != starter) {
      // if You != You
      const requesterName = this.getNameToDisplay(flow.RequestedByUser);
      text = `Started by ${requesterName} on behalf of ${starter}`;
    } else {
      text = `Started by ${starter}`;
    }

    if (shouldIncludeTime) {
      text += ' on ' + flow.MomentStartedFullDate;
    } else {
      text += ' ' + flow.MomentStarted;
    }

    return text;
  }

  private getNameToDisplay(user: IUserBasicInfo): string {
    const currentUser = this.sessionService.getUser();
    if (currentUser.email == user.Email) {
      return 'You';
    } else {
      return user.FirstName + ' ' + user.LastName;
    }
  }

  /**
   * Given an IFlow, return an IFormattedFlow copy of the flow.
   *
   * @param _flow_
   * @param config
   */
  public formatFlow(
    _flow_: IFlow,
    config: IFlowFormatOptions = defaultFlowFormatOption
  ) {
    const flow = angular.copy(_flow_);
    const _user = this.sessionService.getUser();

    const formattingTool = new FormattingTool(config);
    formattingTool.setCurrentUser(_user);

    if (!this.fileserviceInitiated) {
      if (_user) {
        this.fileService.setUser(_user.id, this.tokenService.getTenant().id);
        this.fileserviceInitiated = true;
      }
    }

    flow.WhoStarted = formattingTool.getActorName(flow.StartedByName);
    flow.MomentStarted = this.flowinglyMomentService.isOverOneDayAgo(
      flow.StartedDate
    )
      ? ' on ' +
        this.flowinglyMomentService.formatFullDate(flow.StartedDate, 'LL')
      : this.flowinglyMomentService.calculateRelativeTime(flow.StartedDate);
    flow.MomentStartedFullDate = this.flowinglyMomentService.formatFullDate(
      flow.StartedDate,
      'LLLL'
    );
    flow.MomentWaitingFor = this.flowinglyMomentService.isOverOneDayAgo(
      flow.WaitingForDate
    )
      ? this.flowinglyMomentService.formatFullDate(flow.WaitingForDate, 'LL')
      : this.flowinglyMomentService.calculateRelativeTime(flow.WaitingForDate);
    flow.MomentWaitingForLabel = this.flowinglyMomentService.isOverOneDayAgo(
      flow.WaitingForDate
    )
      ? 'on '
      : ' ';
    flow.MomentFinalised = this.flowinglyMomentService.calculateRelativeTime(
      flow.FinalisedDate
    ); // was "momentCompleted"
    flow.MomentFinalisedFullDate = this.flowinglyMomentService.formatFullDate(
      flow.FinalisedDate,
      'LLLL'
    ); // was "momentCompleted"
    flow.MomentFinalisedInLocalFormat =
      this.flowinglyMomentService.formatUTCToLocal(flow.FinalisedDate);
    flow.MomentDue = this.flowinglyMomentService.calculateDeadlineTime(
      flow.DueDate
    );
    flow.DueDateInLocalFormat = this.flowinglyMomentService.formatUTCToLocal(
      flow.DueDate
    );
    flow.FinalisedInLocalFormat = this.flowinglyMomentService.formatUTCToLocal(
      flow.FinalisedDate
    );
    flow.CurrentStepsHeader = flow.CurrentStepsHeader || '';
    flow.IsOverdue = this.flowinglyMomentService.isOverdue(flow.DueDate);
    flow.IsFinalised = flow.FinalisedDate !== null;
    flow.CCUsers = this.getDisplayNames(flow.CCUsers);

    this.lodashService.forEach(flow.Steps, (step) =>
      this.formatStep(flow, step, formattingTool)
    );

    return flow;
  }

  public formatStep(
    flow: IFormattedFlow,
    step: IStep,
    formattingTool: FormattingTool
  ) {
    this.lodashService.forEach(step.CompletedApprovals, (approval) => {
      approval.RelativeApprovalDate =
        this.flowinglyMomentService.calculateRelativeTime(
          approval.ApprovalDate
        );
    });

    // This is doubling up with what we have in Steps.Fields but I had trouble giving Steps.Fields to FormGen
    // and so decided to sidestep the issue and reatain Step.CardTasks
    const cardTasks = step.CardTasks ? JSON.parse(step.CardTasks) : null;
    step.ParsedCardTasks = cardTasks;
    step.Schema = { fields: [] };

    if (cardTasks !== null && cardTasks !== undefined) {
      // Quick fix to get this working - ie check for formElements or FormElements
      let fe = cardTasks.FormElements;
      if (cardTasks.FormElements === undefined) {
        fe = cardTasks.formElements;
      }
      step.Schema.fields = this.flowsUtilityService.toCamel(fe);

      if (!this.appConfig.enableFormFieldReCaptcha) {
        step.Schema.fields = step.Schema.fields.filter(
          (f) => f.type != this.flowinglyConstants.formFieldType.RECAPTCHA
        );
      }

      if (!step.IsCompleted) {
        if (flow.CurrentStepsHeader.length > 0)
          flow.CurrentStepsHeader = flow.CurrentStepsHeader + ', ' + step.Name;
        else flow.CurrentStepsHeader = step.Name;
      }
    }

    if (flow.IsFinalised) {
      // We dont actually display this anywhere - but for completeness the
      // variable does show the most accurate info - in case it is needed
      if (flow.FinalisedReason === 'Rejected') {
        flow.CurrentStepsHeader = 'Rejected';
      } else {
        flow.CurrentStepsHeader = flow.FinalisedReason;
      }
    }

    step.WhoCompletedStep = formattingTool.getActorName(step.CompletedByName);

    switch (step.StepType) {
      case this.flowinglyConstants.taskType.PARALLEL_APPROVAL: {
        let approverNamesArray = step.StepApproverNames.split(', ');
        approverNamesArray = approverNamesArray.map((nextApprover) => {
          return formattingTool.getActorName(nextApprover);
        });
        step.WhoIsAssignedStep = formattingTool.getActorName(
          approverNamesArray.join(', ')
        );
        break;
      }
      case this.flowinglyConstants.taskType.SEQUENTIAL_APPROVAL:
        // FLOW-2752 Display all selected approvers in Runner
        if (step.StepApproverNames) {
          const approvers = step.StepApproverNames.split(', ');
          const user1 = approvers.shift();
          const userN = approvers.pop();
          const others = approvers;

          let str = formattingTool.getActorName(user1);

          if (others.length) {
            str +=
              ' then ' +
              others.map((u) => formattingTool.getActorName(u)).join(', ');
          }

          if (userN) {
            str += ' and ' + formattingTool.getActorName(userN);
          }

          step.WhoIsAssignedStep = str;
        }
        break;
      default:
        step.WhoIsAssignedStep = step.DelegatorUserName
          ? `${formattingTool.getActorName(
              step.ActorAssignedName
            )} on behalf of ${formattingTool.getActorName(
              step.DelegatorUserName
            )}`
          : formattingTool.getActorName(step.ActorAssignedName);
        break;
    }

    this.lodashService.forEach(step.StepReassignmentHistoryItems, (item) => {
      item.MomentFromNowWithNoPrefix =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          item.ReassignmentDateTime
        );
      item.MomentFromNow = this.flowinglyMomentService.calculateRelativeTime(
        item.ReassignmentDateTime
      );
    });

    if (step.CompletedDate) {
      step.Moment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          step.CompletedDate
        );
      step.CompletedDateMoment =
        this.flowinglyMomentService.calculateRelativeTimeOrFullDate(
          step.CompletedDate
        );
    } else if (
      step.StepReassignmentHistoryItems &&
      step.StepReassignmentHistoryItems.length > 0
    ) {
      step.Moment =
        step.StepReassignmentHistoryItems[
          step.StepReassignmentHistoryItems.length - 1
        ].MomentFromNowWithNoPrefix;
    } else {
      step.Moment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          step.CreatedDate
        );
    }

    step.WhoUpdatedStep = formattingTool.getActorName(step.UpdatedByName);
    step.DeadLineLabel = 'None';
    if (step.DueDate !== null) {
      step.DeadLineLabel = this.flowinglyMomentService.calculateDeadlineTime(
        step.DueDate
      );
      step.IsOverdue = this.flowinglyMomentService.isOverdue(step.DueDate);
      step.MomentFinalisedInLocalFormat =
        this.flowinglyMomentService.formatUTCToLocal(step.DueDate);
    }
    step.HasFields = false;
    this.lodashService.forEach(step.Fields, (field) =>
      this.formatField(flow, step, field)
    );

    if (step.StepTasks != null) {
      this.lodashService.forEach(step.StepTasks, (stepTask) => {
        this.formatStepTask(stepTask, formattingTool);
      });
    }

    if (
      step.IntegrationState != null &&
      step.IntegrationState.State ===
        this.flowinglyConstants.stepIntegrationState.Processing
    ) {
      step.IntegrationState.ProcessingIntegrationMoment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          step.IntegrationState.CreatedDateUtc
        );
    }
  }

  public formatField(flow: IFormattedFlow, step: IStep, field: any) {
    const fieldMetaData = step.Schema.fields.find((x) => x.name == field.Name);

    step.HasFields = true;
    if (
      field.Type === fieldTypes.tasklist ||
      field.Type === fieldTypes.multiselectlist
    ) {
      if (field.Value === null) {
        field.Value = ''; // For the current step
        field.Text = ''; // For step history
      }
    }

    // weird ass pattern - Cassey
    switch (field.Type) {
      case fieldTypes.approvecomment:
        field.Type = 'approvecomment';
        break;
      case fieldTypes.approval:
        field.Type = 'approval';
        break;
      case fieldTypes.checkbox:
        field.Type = 'checkbox';
        break;
      case fieldTypes.currency:
        field.Type = 'currency';
        break;
      case fieldTypes.fileupload:
        field.Type = 'fileupload';
        break;
      case fieldTypes.instruction:
        field.Type = 'instruction';
        break;
      case fieldTypes.table:
        field.Type = 'table';
        break;
      case fieldTypes.textArea:
        field.Type = 'textArea';
        break;
      case fieldTypes.number:
        field.Type = 'number';
        break;
      case fieldTypes.radiobuttonlist:
        field.Type = 'radiobuttonlist';
        break;
      case fieldTypes.dropdown:
        field.Type = 'dropdown';
        break;
      case fieldTypes.tasklist:
        field.Type = 'tasklist';
        break;
      case fieldTypes.multiselectlist:
        field.Type = 'multiselectlist';
        break;
      case fieldTypes.signature:
        field.Type = 'signature';
        break;
      case fieldTypes.dynamicactors:
        field.Type = 'dynamicactors';
        break;
      case fieldTypes.lookup:
        field.Type = 'lookup';
        break;
      case fieldTypes.attachdocument:
        field.Type = 'attachdocument';
        break;
      case fieldTypes.image:
        field.Type = 'image';
        break;
      case fieldTypes.recaptcha:
        field.Type = this.flowinglyConstants.formFieldType.RECAPTCHA;
        break;
      default:
        field.Type = 'string';
    }

    switch (field.Type) {
      case 'table':
        this.lodashService.forEach(step.Schema.fields, (schemaField) => {
          if (schemaField.name === field.Name) {
            field.TableSchema = schemaField.tableSchema;
            schemaField.lookupValues = field.LookupValues;
          }
        });
        break;
      case 'fileupload':
        field.HasFiles = false;
        if (
          (field.Value && field.Value !== '') ||
          (field.Text && field.Text !== '')
        ) {
          field.HasFiles = true;
          const fileArray = [];
          const fileMetas = JSON.parse(field.Text);
          if (fileMetas) {
            fileMetas.forEach((fileMeta) => {
              fileArray.push({
                filename: fileMeta.filename,
                downloadLink: this.fileService.getDownloadLink(fileMeta.id)
              });
            });
          }

          field.Text = fileArray;
        }
        break;
      case 'tasklist':
      case 'multiselectlist':
        if (field.Value.length >= 1) {
          field.Text = field.Value = JSON.parse(field.Value);
        }
        break;
      case 'currency':
        if (field.Value) {
          const num = +field.Value.split(' ').shift();
          const code = fieldMetaData.customUnits;

          field.Text = this.currencyService.toCurrency(num, code);
        }
        break;
    }
  }

  public formatStepNudgeHistoryList(nudgeHistoryList, step) {
    for (const nudgeHistory of nudgeHistoryList) {
      this.formatStepNudgeHistory(nudgeHistory, step);
    }

    return nudgeHistoryList;
  }

  public formatStepNudgeHistory(nudgeHistory, step) {
    const config = {
        replaceLoggedInUserWithYou: true
      },
      _user = this.sessionService.getUser(),
      formattingTool = new FormattingTool(config);

    formattingTool.setCurrentUser(_user);

    nudgeHistory.whoNudged = formattingTool.getActorName(
      nudgeHistory.nudgedByUserName
    );
    nudgeHistory.momentNudged =
      this.flowinglyMomentService.calculateRelativeTime(
        nudgeHistory.createdDate
      );
    nudgeHistory.whoIsNudged = formattingTool.getActorName(
      nudgeHistory.actorNameNudged
    );
    nudgeHistory.stepName = step.Name;
  }

  public encodeFlowFormData(formData, schema, isSaveProgress) {
    formData.forEach((data) => {
      if (angular.isString(data.value)) {
        const matchField = schema.find((f) => f.name === data.key);

        if (
          matchField &&
          matchField.type.toLowerCase() !==
            this.flowinglyConstants.formFieldType.TEXTAREA &&
          matchField.type.toLowerCase() !==
            this.flowinglyConstants.formFieldType.TABLE
        ) {
          if (
            isSaveProgress &&
            matchField.type.toLowerCase() ===
              this.flowinglyConstants.formFieldType.TEXT
          ) {
            data.value = this.validationService.sanitizeString(data.value);
          } else {
            data.value = this.kendo.htmlEncode(data.value);
          }
        }
      }
    });
  }

  private getDisplayNames(cc) {
    const listCC = [];

    if (cc && cc.length > 2) {
      const _json = JSON.parse(cc);

      if (_json.users || _json.teams) {
        _json.users.forEach((elem, i) => {
          listCC.push(elem.name);
        });

        _json.teams.forEach((elem, i) => {
          if (elem.teamName === undefined) listCC.push(elem.name);
          else listCC.push(elem.teamName);
        });
      }
    }

    return listCC.toString().replace(',', ', ');
  }

  public formatStepTask(stepTask, formattingTool: FormattingTool = null) {
    const user = this.sessionService.getUser();

    if (formattingTool == null) {
      formattingTool = new FormattingTool(defaultFlowFormatOption);
    }

    formattingTool.setCurrentUser(user);

    // Due date properties.
    // When the task is rejected then the date to use is the UpdatedDateUtc.
    if (stepTask.ApprovalStatus != null && stepTask.ApprovalStatus === 1) {
      stepTask.Moment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          stepTask.UpdatedDateUtc
        );
    } else {
      stepTask.Moment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          stepTask.CreatedDateUtc
        );
    }

    stepTask.DueDateLabel = this.flowinglyMomentService.calculateDeadlineTime(
      stepTask.DueDateTime
    );
    stepTask.MomentDueDateInLocalFormat =
      this.flowinglyMomentService.formatFullDate(
        stepTask.DueDateTime,
        'DD MMM YYYY'
      );

    if (stepTask.Status === 0 || stepTask.Status === 2) {
      // In progress or completed - with assigned user.
      stepTask.WhoIsAssignedStepTask = formattingTool.getActorName(
        stepTask.AssignedUserName
      );
    } else if (stepTask.Status === 1) {
      // Waiting for approval - with approver user.
      stepTask.ApprovalMoment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          stepTask.UpdatedDateUtc
        );
      stepTask.ApprovalDateLabel =
        this.flowinglyMomentService.calculateDeadlineTime(
          stepTask.UpdatedDateUtc
        );
      stepTask.MomentApprovalDateFormat =
        this.flowinglyMomentService.formatFullDate(
          stepTask.UpdatedDateUtc,
          'DD MMM YYYY'
        );

      stepTask.WhoIsAssignedStepTask = formattingTool.getActorName(
        stepTask.ApproverUserName
      );
    }

    if (stepTask.Status === 2) {
      // The step task is completed so there should be a completed date.
      stepTask.CompletedMoment =
        this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
          stepTask.CompletedDateUtc
        );
      stepTask.CompletedDateLabel =
        this.flowinglyMomentService.calculateStepTaskCompletedTime(
          stepTask.CompletedDateUtc
        );
      stepTask.MomentCompletedDateFormat =
        this.flowinglyMomentService.formatFullDate(
          stepTask.CompletedDateUtc,
          'DD MMM YYYY'
        );
    }

    stepTask.DoneMoment =
      this.flowinglyMomentService.calculateRelativeTimeWithNoPrefix(
        stepTask.DoneDateUtc
      );
  }

  /*
   * Reorder step tasks by ordering by due date time and then grouped by current user.
   * Current user step tasks are to be displayed first then step tasks of other users.
   */
  public reorderStepTasks(stepTasks) {
    if (stepTasks != null && stepTasks.length !== 0) {
      const currentUserId = this.sessionService.getUser().id;

      // Sort the step tasks by the DueDateTime.
      const orderedStepTaskByDueDate = this.lodashService.sortBy(
        stepTasks,
        ['DueDateTime'],
        ['asc']
      );

      const tasksByCurrentUserInComplete = [];
      // Get the incomplete step tasks where the current user is either the approver or assignee.
      this.lodashService.forEach(orderedStepTaskByDueDate, (stepTask) => {
        if (
          stepTask.AssignedUserId === currentUserId &&
          stepTask.Status === this.flowinglyConstants.stepTaskStatus.InProgress
        ) {
          tasksByCurrentUserInComplete.push(stepTask);
        }
        if (
          stepTask.ApproverUserId === currentUserId &&
          stepTask.Status ===
            this.flowinglyConstants.stepTaskStatus.WaitingForApproval
        ) {
          tasksByCurrentUserInComplete.push(stepTask);
        }
      });

      const tasksByCurrentUserComplete = [];
      // Get all step tasks that are completed that current users is approver or assignee.
      this.lodashService.forEach(orderedStepTaskByDueDate, (stepTask) => {
        if (
          stepTask.Status ===
            this.flowinglyConstants.stepTaskStatus.Completed &&
          stepTask.AssignedUserId === currentUserId
        ) {
          tasksByCurrentUserComplete.push(stepTask);
        }
      });

      // Combine the current user step tasks that are not completed then completed and the remaining
      // with the other step tasks by removing duplicates.
      const sortedAndOrderedArray = this.lodashService.union(
        tasksByCurrentUserInComplete,
        tasksByCurrentUserComplete,
        orderedStepTaskByDueDate
      );

      return sortedAndOrderedArray;
    }
    return stepTasks;
  }
}

angular
  .module('flowingly.runner.services')
  .service('runnerFlowsFormatter', RunnerFlowsFormatterService);

export type RunnerFlowsFormatterServiceType = InstanceType<
  typeof RunnerFlowsFormatterService
>;
